div.ui.segment.chatbox {
    padding: 0;
}

div.chatbox-wrapper {
    height: 25vh;
    overflow-y: scroll;
}

.hidden-latest-message {
    height: 0;
}

div.chatbox-wrapper > div > div {
    text-align: left;
}

div.chatbox-wrapper .chatbox-form {
    padding: 1px;
    margin: 1px;
}

div.chat-messages .admin {
    color: #e30b5c;
}

div.chat-messages .global {
    color: #afafaf;
    font-style: italic;
}

div.chat-messages .server {
    color: #a47b0a;
}

div.chat-messages .whispers {
    font-weight: normal !important;
    color: yellow;
}

div.chat-messages .trade {
    color: green;
}

div.chat-messages .error {
    color: #cc0000;
}

div.chat-messages .notification {
    color: orange;
}

div.chat-messages .command-output {
    font-style: italic;
}

div.chat-tab * {
    border: none !important;
}

div#chat-message-input-bar {
    height: 14px;
    line-height: 14px;
}
