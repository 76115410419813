.equipped-item {
    border: 3px ridge #282828;
    padding: 5px;
}

.player-item {
    cursor: pointer;
    line-height: 1em;
    font-size: 1em;
}

.rarity {
    font-weight: 700;
}

.rarity.basic {
    color: lightgrey;
}

.rarity.worn-out {
    color: white;
}

.rarity.average {
    color: #2ecc40;
}

.rarity.military-grade {
    color: dodgerblue;
}

.rarity.neutronium-powered {
    color: gold !important;
    text-shadow: 1px 1px 8px gold, 1px 1px 8px #fff;
}
