* {
    font-family: "Kanit", sans-serif;
    font-weight: 0.8;
}

body {
    background-color: #282c34 !important;
}

label {
    color: white !important;
}

.pointer {
    cursor: pointer;
}

.compact.ui.grid {
    padding: 0;
}

.compact.ui.grid .row {
    padding: 0px;
}

.compact.ui.grid .row .column {
    padding: 5px;
}

div.ui.segment {
    padding: 15px;
}

p.double-rates-active {
    color: greenyellow;
    font-size: 1.1em;
    font-weight: 300;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-shadow: 0 0 0.125em #cc0000;
    filter: blur(0.01em);
}

div.boosts-category.ui.grid div.eight.wide.column {
    padding: 15px !important;
}

div.boosts-category.ui.grid div.eight.wide.column p {
    line-height: 1.1em;
    font-size: 1em;
    margin: 1px;
    padding: 1px;
}

.table tr.invisible {
    display: none !important;
}

div.tab {
    border: none !important;
    padding: 2px !important;
}

div.homeplanet-buildings.grid div.eight.wide.column {
    padding: 20px !important;
}

.ui.input.centerAlign > input {
    text-align: center;
}

span.username {
    cursor: pointer;
    color: #009fda;
}

span.username:hover {
    color: #00cfee;
}

.ui.checkbox {
    margin: 3px 10px;
}

div#wrapper {
    margin: auto;
    padding: 0;
    align-items: center !important;
    text-align: center !important;
    vertical-align: center !important;
    justify-content: center !important;
}

div#main-game-content {
    height: 55vh;
    overflow-y: scroll;
}
